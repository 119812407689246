const loanSimulatorEvents = {
  step_01: {
    funnelName: 'calcular_simulacion',
    funnelStep: 'step_01',
  },
  step_02: {
    funnelName: 'resultado_oferta_simulada',
    funnelStep: 'step_02',
  },
  step_03: {
    funnelName: 'cta_contratar',
    funnelStep: 'step_03',
  },
}

export default loanSimulatorEvents
