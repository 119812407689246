import { I18nextContext } from 'gatsby-plugin-react-i18next'
import React, { FC } from 'react'

import * as SanitySchema from '../../lib/types/sanity-schema'
import LoanSimulator from './widgets/LoanSimulator/LoanSimulator'
import WidgetBlogFeed from './widgets/WidgetBlogFeed/WidgetBlogFeed'
import WidgetBolNewUser from './widgets/WidgetBolNewUser/WidgetBolNewUser'
import WidgetCompanyCirculating from './widgets/WidgetCompanyCirculating/WidgetCompanyCirculating'
import WidgetCookieBotDetails from './widgets/WidgetCookieBotDetails/WidgetCookieBotDetails'
import WidgetCurrencyExchangeTable from './widgets/WidgetCurrencyExchangeTable/WidgetCurrencyExchangeTable'
import WidgetCustomForm from './widgets/WidgetCustomForm/WidgetCustomForm'
import WidgetCustomizeYourCard from './widgets/WidgetCustomizeYourCard/WidgetCustomizeYourCard'
import WidgetDeceaseInsurance from './widgets/WidgetDeceaseInsurance/WidgetDeceaseInsurance'
import WidgetDownloadApp from './widgets/WidgetDownloadApp/WidgetDownloadApp'
import WidgetIframe from './widgets/WidgetIFrame/WidgetIFrame'
import WidgetInsuranceCalculator from './widgets/WidgetInsuranceCalculator/WidgetInsuranceCalculator'
import WidgetLogout from './widgets/WidgetLogout/WidgetLogout'
import WidgetMultiInsurance from './widgets/WidgetMultiInsurance/WidgetMultiInsurance'
import WidgetPromosList from './widgets/WidgetPromosList/WidgetPromosList'
import WidgetSiteplan from './widgets/WidgetSiteplan/WidgetSiteplan'
import WidgetTalentclueCareers from './widgets/WidgetTalentclueCareers/WidgetTalentclueCareers'
import WidgetTravelInsurance from './widgets/WidgetTravelInsurance/WidgetTravelInsurance'
import WidgetWeecover from './widgets/WidgetWeecover/WidgetWeecover'

export type LkWidgetProps = Omit<SanitySchema.LkWidget, '_type' | 'widgetType'> & {
  widgetType: string
}

const LkWidgetBuilder: FC<LkWidgetProps> = ({
  widgetType,
  formReference,
  loanSimulatorReference,
  cta,
  legalLink,
  legalLinkPreContractual,
  feed,
  src,
  title,
  maximumAmount,
  minimumAmount,
  minimumDays,
  maximumDays,
  subtitleInsuranceCalculator,
}) => {
  const context = React.useContext(I18nextContext)

  switch (widgetType) {
    case 'app:bol':
      return <WidgetDownloadApp app="bol" legalLink={legalLink} />
    case 'app:lk-pay':
      return <WidgetDownloadApp app="lk-pay" legalLink={legalLink} />
    case 'blog:feed':
      return <WidgetBlogFeed feed={feed} />
    case 'bol:customize-your-card':
      return <WidgetCustomizeYourCard />
    case 'bol:logout':
      return <WidgetLogout />
    case 'bol:new-user':
      return <WidgetBolNewUser />
    case 'cookiebot:details':
      return <WidgetCookieBotDetails language={context.language} />
    case 'currency-exchange:table':
      return <WidgetCurrencyExchangeTable />
    case 'forms:custom-form':
      return <WidgetCustomForm form={formReference} />
    case 'promos:list':
      return <WidgetPromosList />
    case 'simulators:decease-insurance':
      return <WidgetDeceaseInsurance legalLink={legalLink} />
    case 'simulators:loan-simulator':
      return <LoanSimulator simulator={loanSimulatorReference} />
    case 'simulators:multi-insurance':
      return <WidgetMultiInsurance cta={cta} />
    case 'simulators:travel-insurance':
      return <WidgetTravelInsurance legalLink={legalLink} />
    case 'talentclue:careers':
      return <WidgetTalentclueCareers language={context.language} />
    case 'weecover:mobility-insurance':
      return <WidgetWeecover language={context.language} />
    case 'html:iframe':
      return <WidgetIframe src={src} title={title} />
    case 'simulators:insurance-calculator':
      return <WidgetInsuranceCalculator legalLink={legalLink} legalLinkPreContractual={legalLinkPreContractual} />
    case 'simulators:company-circulating-simulator':
      return (
        <WidgetCompanyCirculating
          minimumAmount={minimumAmount}
          maximumAmount={maximumAmount}
          minimumDays={minimumDays}
          maximumDays={maximumDays}
          subtitleInsuranceCalculator={subtitleInsuranceCalculator}
        />
      )
    case 'html:siteplan':
      return <WidgetSiteplan language={context.language} path={context.path} />
    default:
      throw Error(`widget type unknown: ${widgetType}`)
  }
}

export default LkWidgetBuilder
