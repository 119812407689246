import './AccessibleSelect.scss'

import { Icon } from '@lk/lk-design-system'
import React, { useEffect } from 'react'

export type AccessibleSelectOption = {
  value: string
  label: string
}

export interface AccessibleSelectProps {
  name: string
  options: AccessibleSelectOption[]
  label: string
  placeholder: string
  onItemSelected: (value: string) => void
  initialValue: string
  required?: boolean
}

const AccessibleSelect: React.FC<AccessibleSelectProps> = ({
  name,
  options,
  label,
  placeholder,
  onItemSelected,
  initialValue,
  required,
}) => {
  const selectRef = React.createRef<HTMLSelectElement>()
  useEffect(() => {
    if (!placeholder && options?.length > 0) {
      onItemSelected(options[0].value)
    }
  }, [options, placeholder, onItemSelected])
  const id = `accessible-select-${name}`

  return (
    <div className="lk-accessible-select">
      <label htmlFor={id} className="lk-input__label lk-label lk-input__label">
        {required && <i aria-hidden>*</i>} {label}
      </label>
      <div className="lk-accessible-select__input">
        <select
          aria-label={name}
          name={name}
          id={id}
          className="lk-input lk-select lk-input__field"
          onInput={(e) => {
            const { value: valueSelected } = e?.target as HTMLSelectElement
            onItemSelected(valueSelected)
          }}
          placeholder={placeholder}
          defaultValue={initialValue || ''}
          required={required}
          ref={selectRef}
        >
          {placeholder && (
            <option value="" disabled hidden>
              {placeholder}
            </option>
          )}
          {options.map(({ value: optionValue, label: optionLabel }, index) => {
            const key = `select-${name}-option-${index}`
            return (
              <option
                key={key}
                id={`tab-${optionLabel}`}
                value={optionValue}
                className="lk-dropdown-item lk-dropdown-item__label"
              >
                {optionLabel}
              </option>
            )
          })}
        </select>
        <button
          type="button"
          onClick={() => selectRef?.current?.showPicker()}
          className="lk-select__icon"
          aria-label="select"
        >
          <Icon name="chevron-down" />
        </button>
      </div>
    </div>
  )
}

AccessibleSelect.defaultProps = {
  required: false,
}

export default AccessibleSelect
